import React from "react"
import Chart from './chart'
import { VictoryBar } from "victory"

class BarChart extends React.Component {
  render() {
    return(
      <Chart caption={ this.props.caption } domainPadding={25}>
        <VictoryBar { ...this.props } />
      </Chart>
    )
  }
}

export default BarChart

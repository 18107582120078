import React from "react"
import { VictoryLine } from "victory"
import Chart from './chart'

class LineChart extends React.Component {
  render() {
    return(
      <Chart caption={ this.props.caption }>
        <VictoryLine { ...this.props } />
      </Chart>
    )
  }
}

export default LineChart

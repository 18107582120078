import React from "react"
import { VictoryChart } from "victory"
import theme from "./charts_theme.js"

class Chart extends React.Component {
  render() {
    return(
      <figure className={ "chart" }>
        <VictoryChart theme={ theme } { ...this.props }>
          { this.props.children }
        </VictoryChart>
        <figcaption>{ this.props.caption }</figcaption>
      </figure>
    )
  }
}

export default Chart

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styles from "./layout.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { MDXProvider } from '@mdx-js/react'
import AniLink from "gatsby-plugin-transition-link/AniLink"

import LineChart from './line_chart'
import BarChart from './bar_chart'

import Header from "./header"

const shortcodes = { LineChart, BarChart }

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <MDXProvider components={ shortcodes }>
      <div className={styles.wrapper}>
        <Header className={styles.header} siteTitle={data.site.siteMetadata.title} />
        <main className={styles.main}>
          {children}
        </main>
        <footer className={styles.footer}>
          © Sigve Indregard, 2006-{new Date().getFullYear()}.&nbsp;
          <AniLink to="/kontakt" swipe direction="down">Kontaktinformasjon</AniLink>
        </footer>
      </div>
    </MDXProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import { StaticQuery, graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Image from "gatsby-image"
import React from "react"
import styles from "./Header.module.scss"


export default () => (
  <StaticQuery
    query={graphql`
        query {
          site {
            siteMetadata {
              title
            }
          }
          file(relativePath: { eq: "logo.png" }) {
            childImageSharp {
              fixed(width: 150) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
    `}
    render={ data => (
      <header className={styles.header}>
        <div className={styles.brand}>
          <AniLink to="/" swipe direction="down">
            <Image fixed={data.file.childImageSharp.fixed} alt="Logo" />
          </AniLink>
          <span className={styles.siteTitle}>
            { data.site.siteMetadata.title }
          </span>
        </div>
        <nav className={styles.menu}>
          <ul>
            <li><AniLink to="/blogg" fade>Blogg</AniLink></li>
            <li><AniLink to="/minne" fade>Mine minne</AniLink></li>
            <li>
              <AniLink to="/kontakt" swipe direction="down">
                Kontakt
              </AniLink>
            </li>
          </ul>
        </nav>
      </header>
    )}
  />
)
